import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/b5yvV_RcX4U"
    bibleGroupSrc="https://www.docdroid.net/wJi9Yv3/bible-group-homework-9-13-pdf"
    bibleGroupText="Bible Group Homework"
    notesText="Sermon Notes"
    notesSrc="http://bible.com/events/40112555"
  >
    <SEO title="Together for the Mission - Together is Better" />
  </Layout>
)

export default SermonPost
